import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { LinkField } from '@prismicio/client';
import { LinkComponent } from 'link';
import { OfferCtaSlice } from './offer-cta.slice';

@Component({
  selector: 'lib-offer-box-button',
  templateUrl: './offer-box-button.component.html',
  styleUrls: ['./offer-box-button.component.scss'],
  standalone: true,
  imports: [NgClass, LinkComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferBoxButtonComponent {
  @Input() slice: OfferCtaSlice;
  @Input() isHeroOffer = false;
  @Input() offerBoxSide?: 'right' | 'left';
  @Input() offerLink: LinkField;
  @Input() queryParams: object;
  @Output() offerButtonClick = new EventEmitter<void>();
  darkMode = false;
}
