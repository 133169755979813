<div class="flex relative" [ngClass]="{ 'justify-center': !offerBoxSide }">
  <lib-link
    [link]="offerLink"
    [queryParams]="queryParams"
    (onClick)="offerButtonClick.emit($event)"
    class="w-full text-center"
  >
    <div
      class="button w-full sm:w-3/4 text-center text-lg font-bold"
      [ngClass]="{
        'xl:w-2/4': isHeroOffer,
        'custom-button': offerBoxSide,
        'ml-auto': offerBoxSide === 'right',
        'featured pulse': slice.buttonColor === 'Red' || slice.buttonColor === 'Featured',
      }"
      [style.backgroundColor]="slice.buttonColor"
    >
      {{ slice.buttonText }}
    </div>
  </lib-link>
</div>
