import { Image, Promotion } from 'interfaces';
import { Offer, OfferDocument } from 'prismic';
import { PCProduct } from 'product';
import { OfferCtaSlice } from './offer-box-button/offer-cta.slice';

const prismicData: OfferDocument = {
  data: {
    featureflag_only: 'featureflag_only',
    offer_image: undefined,
    offer_content: [],
    body: [],
    use_custom_content: false,
    title: [
      {
        type: 'heading4',
        text: 'text',
        spans: [],
      },
    ],
    title_alignment: 'center',
    content_alingment: 'center',
    normal_price: '10',
    promo_price: '5',
    price_size: 'medium',
    price_prefix: '',
    text_after_price: [
      {
        type: 'heading4',
        text: 'text',
        spans: [],
      },
    ],
    check_marks: [
      {
        check_mark_content: [
          {
            type: 'heading4',
            text: 'text',
            spans: [],
          },
        ],
      },
    ],
    text_after_check_marks: [
      {
        type: 'heading4',
        text: 'text',
        spans: [],
      },
    ],
    product_code: 'test',
  },
  uid: null,
  id: '',
  url: '',
  type: 'offer',
  href: '',
  tags: [],
  first_publication_date: null,
  last_publication_date: null,
  slugs: [],
  linked_documents: [],
  lang: '',
  alternate_languages: [],
};

export const offerMockWithProductLinked = new Offer(
  prismicData,
  'content',
  {
    original: new Image('http://localhost', { width: 800, height: 200 }),
    smallest: new Image('http://localhost', { width: 200, height: 100 }, 'alt', 'copyright'),
    htmlSrcset: 'app',
    htmlSizes: 'app',
    alt: 'alt',
  },
  {
    product: new PCProduct(),
    promotion: new Promotion(),
  },
  [
    {
      buttonColor: 'Primary',
      buttonLink: {
        link_type: 'Document',
        url: '/path/to/offers',
      },
      buttonText: 'label',
      type: 'offer_cta',
      label: 'label',
      primary: 'primary',
      items: [],
    } as OfferCtaSlice,
  ]
);

export const offerMockWithCustomPromotionBanner = new Offer(
  prismicData,
  'content',
  {
    original: new Image('http://localhost', { width: 800, height: 200 }),
    smallest: new Image('http://localhost', { width: 200, height: 100 }, 'alt', 'copyright'),
    htmlSrcset: 'app',
    htmlSizes: 'app',
    alt: 'alt',
  },
  {
    product: new PCProduct(),
    promotion: new Promotion(),
  },
  [
    {
      buttonColor: 'Primary',
      buttonLink: {
        link_type: 'Document',
        url: '/path/to/offers',
      },
      buttonText: 'label',
      type: 'offer_cta',
      label: 'label',
      primary: 'primary',
      items: [],
    } as OfferCtaSlice,
  ]
);

export const offerMockNormal = new Offer(
  prismicData,
  'content',
  {
    original: new Image('http://localhost', { width: 800, height: 200 }, 'alt', 'copyright'),
    smallest: new Image('http://localhost', { width: 200, height: 100 }, 'alt', 'copyright'),
    htmlSrcset: 'app',
    htmlSizes: 'app',
    alt: 'alt',
  },
  undefined,
  [
    {
      buttonColor: 'Primary',
      buttonLink: {
        link_type: 'Document',
        url: '/path/to/offers',
      },
      buttonText: 'label',
      type: 'offer_cta',
      label: 'label',
      primary: 'primary',
      items: [],
    } as OfferCtaSlice,
  ]
);
