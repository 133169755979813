import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FilledContentRelationshipField, LinkField } from '@prismicio/client';
import { DynamicHooksComponent } from 'ngx-dynamic-hooks';
import { CountdownComponent } from 'countdown';
import { Promotion, SliceClass } from 'interfaces';
import { LinkComponent } from 'link';
import { Offer } from 'prismic';
import { PCProduct, ProductService } from 'product';
import { TooltipComponent } from 'tooltip';
import { TranslateService } from 'translate';
import { CurrencyFormatterPipe } from 'utils';
import { OfferBoxButtonComponent } from './offer-box-button/offer-box-button.component';
import { OfferCtaSlice } from './offer-box-button/offer-cta.slice';
import { OfferBoxImageComponent } from './offer-box-image/offer-box-image.component';
import { OfferBoxPriceComponent } from './offer-box-price/offer-box-price.component';
import { OfferStickerComponent } from './offer-sticker/offer-sticker.component';
import { OfferStickerSlice } from './offer-sticker/offer-sticker.slice';

@Component({
  selector: 'lib-offer-box',
  templateUrl: './offer-box.component.html',
  styleUrls: ['./offer-box.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    LinkComponent,
    NgTemplateOutlet,
    OfferStickerComponent,
    NgClass,
    DynamicHooksComponent,
    OfferBoxButtonComponent,
    OfferBoxPriceComponent,
    OfferBoxImageComponent,
    TooltipComponent,
    CurrencyFormatterPipe,
    CountdownComponent,
  ],
})
export class OfferBoxComponent implements OnInit {
  private translateService = inject(TranslateService);
  private productService = inject(ProductService);
  @Input() offer: Offer;
  @Input() size: 'small' | 'medium' = 'medium';
  @Input() lazyLoadImage = true;
  offerLink: LinkField;
  promotionBanner: Partial<OfferStickerSlice>;
  isProductOffer: boolean;
  @Input() isHeroOffer = false;
  @Output() offerClick = new EventEmitter<{ product: PCProduct; promotion: Promotion }>();

  get containerCssClass() {
    return `text-${this.offer.contentAlignment} contents`;
  }

  get titleCssClass() {
    return `text-${this.offer.titleAlignment} mb-3`;
  }

  get priceFontSize() {
    return `offer-box-font-size_${this.offer?.priceSize}`;
  }

  //TODO: this can be reusable
  getCheckoutFlow(product: PCProduct) {
    const orderType = 'new';
    return `${orderType}-${product.type}`;
  }

  ngOnInit() {
    this.isProductOffer = !!this.offer?.productListItem;
    this.handleOfferLink();
    this.handlePromoBanner();
  }

  handleOfferLink() {
    const offerSliceCTA = this.offer?.slices?.find(slice => slice.type === 'offer_cta');
    if (offerSliceCTA && (offerSliceCTA as OfferCtaSlice).buttonLink) {
      this.formatOfferLink(offerSliceCTA);
    } else {
      if (this.isProductOffer && offerSliceCTA) {
        this.offerLink = this.productService.buildCheckoutPath(
          this.offer.productListItem.product,
          this.offer.productListItem.promotion
        ).link;
      }
    }
  }

  formatOfferLink(offerSliceCTA: SliceClass) {
    const linkTypeIsDocument = (offerSliceCTA as OfferCtaSlice).buttonLink.link_type === 'Document';
    const slug = ((offerSliceCTA as OfferCtaSlice).buttonLink as FilledContentRelationshipField).slug;
    if (this.offer?.productListItem?.promotion && linkTypeIsDocument && slug?.includes('promotions')) {
      // remove promotions added on button_link text to avoid duplicated params, if any (we already got it on prismic.service - getCorrectPromotion)
      const CTALink: LinkField = {
        slug: slug.substring(0, slug.indexOf('?')),
        link_type: 'Document',
      };
      this.offerLink = CTALink;
    } else {
      this.offerLink = (offerSliceCTA as OfferCtaSlice).buttonLink;
    }
  }

  getQueryParams() {
    return { promotions: this.offer?.productListItem?.promotion?.code };
  }

  handlePromoBanner() {
    const promotionBannerFromPrismic = this.offer?.slices?.find(item => item.type === 'promo_banner');
    this.promotionBanner = promotionBannerFromPrismic
      ? promotionBannerFromPrismic
      : this.offer?.productListItem?.promotion
        ? {
            color: 'featured',
            text:
              this.offer.productListItem.promotion.title ||
              `${Promotion.discountPercentage(
                Number(this.offer.productListItem.product.monthly_cost),
                this.offer.productListItem.promotion.discountAmount
              )}% ${this.translateService.getTranslation(['discount'])}`,
          }
        : undefined;
  }

  onOfferClick() {
    if (this.isProductOffer) {
      if (this.productService.isLinkToCheckout(this.offerLink)) {
        const product = this.offer.productListItem.product;
        const promotion = this.offer.productListItem.promotion;
        this.offerClick.emit({ product, promotion });
      }
    }
  }

  showCustomContent() {
    return (
      this.offer?.useCustomContent ||
      this.offer?.useCustomContent === null ||
      this.offer?.useCustomContent === undefined
    );
  }
}
